body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.editor-class {
  background-color: #f6f6f6;
  height: 12rem !important;
  padding: 1rem;
}

.animation {
  animation: move 1.8s ease-in-out infinite alternate;
}

@keyframes move {
  0% {
    transform: translateX(-0.3rem);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateX(0%);
    transform: translateY(0%);
  }
}

.css-gq2d9e-MuiTabs-indicator {
  display: none !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}
/* .MuiTypography-h4 {
  font-size: 2.125rem; 
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   font-weight: 400; 
   line-height: 1.235;
} */
.MuiListItemIcon-root {
  min-width: 0px !important;
}
.MuiButtonBase-root {
  padding: 8px !important;
}
