.chatComponent {
  width: 82%;
}
@media (max-width: 1200px) {
  .chatComponent {
    width: 88% !important;
  }
}

/* @media(max-width:900px) {
    .sidebar-menu {
        display: "none !important"

    }
} */
@media (max-width: 1535px) {
  .chatComponent {
    width: 78%;
  }
}
@media (max-width: 1380px) {
  .chatComponent {
    width: 77%;
  }
}

@media (max-width: 1220px) {
  .chatComponent {
    width: 76%;
  }
}

@media (max-width: 1150px) {
  .chatComponent {
    width: 74%;
  }
}
@media (max-width: 1070px) {
  .chatComponent {
    width: 72%;
  }
}

.senderListItem {
  display: flex;
  justify-content: flex-end;
}
.receiverListItem {
  display: flex;
  justify-content: flex-start;
}
.sender {
  background-color: rgba(28, 106, 174, 1);
  border-top-left-radius: 2rem; /* Apply border radius to the top right corner */
  border-bottom-left-radius: 2rem; /* Apply border radius to the bottom right corner */
  border-top-right-radius: 2rem; /* Apply border radius to the top right corner */
  width: fit-content;
  min-width: 10%;
  margin-bottom: 4px;
  max-width: 50%;
  word-wrap: break-word;
  padding: 0.5rem 1rem 0.5rem 1.5rem;

  color: rgb(255, 255, 255);
}

/* .senderListItem:has(.hello) {
  background-color: yellow;

} */
.receiver {
  background-color: rgba(246, 139, 30, 1);
  border-top-right-radius: 2rem; /* Apply border radius to the top right corner */
  border-bottom-right-radius: 2rem; /* Apply border radius to the bottom right corner */
  width: fit-content;
  min-width: 10%;
  margin-bottom: 1px;
  max-width: 50%;
  word-wrap: break-word;
  padding: 0.5rem 1rem;
  color: white;
}

@media (max-width: 600px) {
  .sender {
    max-width: 90%;
  }
  .receiver {
    max-width: 90%;
  }
}

.MuiTabs-indicator {
  display: none;
}
.css-aszp7s-MuiTable-root {
  background-color: white !important;
}

/* .css-1t1vqb5-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 2rem !important;
  }  */
